import React from 'react';
import primaIcon from './prima_monogram.svg';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  logo: {
    width: 'auto',
    height: 30,
    filter: theme.palette.type === 'dark' ? 'brightness(99)' : 'none',
  },
}));

const LogoIcon = () => {
  const classes = useStyles();

  return <img className={classes.logo} src={primaIcon} alt="Prima logo" />;
};

export default LogoIcon;
