import {
  lightTheme,
  darkTheme,
  genPageTheme,
  shapes,
  UnifiedThemeProvider,
  createUnifiedTheme,
} from '@backstage/theme';
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/Brightness2';
import React from 'react';
import { AppTheme } from '@backstage/core-plugin-api';

const primaLightTheme = createUnifiedTheme({
  palette: {
    ...lightTheme.palette,
    background: {
      default: '#f8f8f8',
      paper: '#fff',
    },
    primary: {
      main: '#067f96',
      dark: '#045767',
    },
    secondary: {
      main: '#8334c2',
      dark: '#5b2488',
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#8334c2'], shape: shapes.wave2 }),
  },
});

const primaDarkTheme = createUnifiedTheme({
  palette: {
    ...darkTheme.palette,
    primary: {
      main: '#85becc',
      dark: '#067f96',
    },
    secondary: {
      main: '#8334c2',
      dark: '#5b2488',
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#8334c2'], shape: shapes.wave2 }),
  },
});

const themes: AppTheme[] = [
  {
    id: 'light-theme',
    title: 'Prima Light',
    variant: 'light',
    icon: <LightIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={primaLightTheme} children={children} />
    ),
  },
  {
    id: 'dark-theme',
    title: 'Prima Dark',
    variant: 'dark',
    icon: <DarkIcon />,
    Provider: ({ children }) => (
      <UnifiedThemeProvider theme={primaDarkTheme} children={children} />
    ),
  },
];

export default themes;
